import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Collapsible from 'react-collapsible';

const Sidebar = ({ show, handleClose }) => {
   const Home = <NavLink to="/home">Inicio</NavLink>
   const Doctors = <NavLink to="/doctors">Especialistas </NavLink>
   const ME = <NavLink to="/services">Medicina Estética </NavLink>
   const TF = <NavLink to="/servicesTwoTwo">Tratamientos Faciales </NavLink>
   const TC = <NavLink to="/servicesThree">Tratamientos Corporales </NavLink>
   const Pages = <a href="#">Más</a>
   return (
      <>

         <div >
            <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
               <Offcanvas.Header closeButton>
               </Offcanvas.Header>
               <Offcanvas.Body>


                  <Collapsible trigger={Home} triggerTagName="div"
                     triggerOpenedClassName="icon_close">
                  </Collapsible>

                  <Collapsible trigger={Doctors} triggerTagName="div"
                     triggerOpenedClassName="icon_close">
                  </Collapsible>

                  <Collapsible trigger={ME} triggerTagName="div"
                     triggerOpenedClassName="icon_close">
                  </Collapsible>
                  <Collapsible trigger={TF} triggerTagName="div"
                     triggerOpenedClassName="icon_close">
                  </Collapsible>
                  <Collapsible trigger={TC} triggerTagName="div"
                     triggerOpenedClassName="icon_close">
                  </Collapsible>


                  <Collapsible trigger={Pages} triggerTagName="div"
                     triggerOpenedClassName="" triggerClassName="iconAdd" open={false}>
                     <ul className="sidebar_sub_menu text-white mt-3">
                        <li><NavLink to="/about">Acerca de</NavLink></li>
                        <li><a href="https://clinicawomeds.com/blog/author/admin/">Blog</a></li>
                        <li><NavLink to="/contact">Contacto</NavLink></li>
                     </ul>
                  </Collapsible>
               </Offcanvas.Body>
            </Offcanvas>
         </div>
      </>
   );
};

export default Sidebar;